import React from "react";

type Props = {
  fullHeight?: boolean;
  color?: string;
};

const Loader: React.FC<Props> = ({ fullHeight, color }) => {
  return (
    <div
      className={`flex justify-center items-center ${
        fullHeight ? "min-h-screen" : "h-[300px]"
      }`}
    >
      <div className="relative">
        <div
          className={`w-12 h-12 border-4 border-t-transparent border-${
            color ? color : "primaryColor"
          } rounded-full animate-spin`}
        ></div>
        <div className="absolute inset-0 flex justify-center items-center">
          <div
            className={`w-4 h-4 bg-${
              color ? color : "primaryColor"
            } rounded-full animate-ping`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
