import Image from "next/image";
import React from "react";

type Props = {
  index: number;
  icon: string;
  title: string;
  description: string;
  colSpan?: "full" | "2/3" | "1/3";
  iconWidth?: number;
  iconHeight?: number;
};

const FeatureCard: React.FC<Props> = ({
  index,
  icon,
  title,
  description,
  colSpan = "1/3",
  iconWidth = 40,
  iconHeight = 40,
}) => {
  const colSpanClass = {
    full: "col-span-2 lg:col-span-6",
    "2/3": "col-span-2 lg:col-span-3",
    "1/3": "col-span-2 lg:col-span-2",
  }[colSpan];

  return (
    <div
      data-aos="fade-up"
      data-aos-delay={index * 100}
      className={`w-full px-6 py-8 bg-gradient-to-b from-[#1c1738] to-[#1c1738] rounded-lg border border-[#1c1738] backdrop-blur-md flex flex-col justify-center items-center gap-4 ${colSpanClass} transition-all duration-300 hover:shadow-lg hover:shadow-[#1c1738]/30 hover:-translate-y-1`}
    >
      <div
        className="p-4 bg-gradient-to-b from-[#120d2e] to-[#120d2e] rounded-full border border-[#1c1738] flex justify-center items-center transition-all duration-300 hover:scale-110"
        data-aos="zoom-in"
        data-aos-delay={index * 100 + 200}
      >
        <div className="p-4 bg-gradient-to-b from-[#120d2e] to-[#120d2e] rounded-full border border-[#1c1738] flex justify-center items-center">
          <Image
            src={icon}
            alt={title}
            width={iconWidth}
            height={iconHeight}
          />
        </div>
      </div>
      <div
        className="flex flex-col justify-start items-center gap-4"
        data-aos="fade-up"
        data-aos-delay={index * 100 + 300}
      >
        <h2 className="text-center text-white text-2xl font-semibold capitalize leading-9 transition-all duration-300 hover:text-[#928cb4]">
          {title}
        </h2>
        <p className="text-center text-[#928cb4] transition-all duration-300 hover:text-white">
          {description}
        </p>
      </div>
      <div className="w-3/4 h-3/4 bg-[#140e30] rounded-full blur-[150px] absolute -z-10" />
    </div>
  );
};

export default FeatureCard;
