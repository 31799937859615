"use client";
import Header from "@/components/shared/Header";
import Footer from "@/components/shared/Footer";
import Hero from "@/components/shared/Home/Hero";
import Features from "@/components/shared/Features";

import heroBG from "@/public/images/assets/heroBG.svg";
import WhyUs from "@/components/shared/Home/WhyUs";
import ContactUS from "@/components/shared/Home/ContactUS";
import FAQ from "@/components/shared/Home/FAQ";
import LatestBlogs from "@/components/shared/Home/LatestBlogs";
import AnalysesSection from "@/components/shared/Home/AnalysesSection";
import Reviews from "@/components/shared/Home/Reviews";
import HowWork from "@/components/shared/Home/HowWork";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      // easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <Header />
      <div
        style={{
          backgroundImage: `url(${heroBG.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Hero />
      </div>
      <Features />
      <HowWork />
      <WhyUs />
      {/* <Reviews /> */}
      <LatestBlogs />
      <AnalysesSection />
      <FAQ />
      <ContactUS />
      <Footer />
    </>
  );
}
