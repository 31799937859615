import MainTitle from "@/components/shared/MainTitle";
import whyUs1 from "@/public/images/assets/whyUs1.svg";
import whyUs2 from "@/public/images/assets/whyUs2.svg";
import whyUs3 from "@/public/images/assets/whyUs3.svg";
import whyUs4 from "@/public/images/assets/whyUs4.svg";
import whyUs5 from "@/public/images/assets/whyUs5.svg";
import whyUs6 from "@/public/images/assets/whyUs6.svg";
import shieldUp from "@/public/images/icons/shield-up.svg";
import Image from "next/image";
import whyUsIcon1 from "@/public/images/icons/why-us-1.svg";
import whyUsIcon2 from "@/public/images/icons/why-us-2.svg";
import whyUsIcon3 from "@/public/images/icons/why-us-3.svg";
import whyUsIcon4 from "@/public/images/icons/why-us-4.svg";
import "aos/dist/aos.css";

const WhyUs = () => {
  return (
    <section
      id="why-Us"
      className="flex flex-col gap-4 overflow-x-clip"
    >
      <div data-aos="fade-up">
        <MainTitle
          subTitle="Why Us"
          title="Why you need vulnvision ?"
        />
      </div>

      <div className="container pb-10">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
          <Feature
            icon={whyUs1}
            title="User-Friendly Interface"
            description="VulnVision, designed with user-friendly features, ensures accessibility for all, simplifying cybersecurity for both experts and novices alike."
            aosDelay={0}
          />
          <Feature
            icon={whyUs2}
            title="Multipart Notifications"
            description="VulnVision keeps you informed of security threats through multiple communication channels, so you can take immediate action no matter where you are.."
            aosDelay={200}
          />
          <Feature
            icon={whyUs3}
            title="Advanced Statistics"
            description="Vulnerabilities statistics employ sophisticated methodologies to scrutinize and interpret complex data patterns, offering valuable insights for strategic cybersecurity decision-making "
            aosDelay={400}
          />
        </div>
        <div
          className="mb-4 grid grid-cols-1 md:grid-cols-2 whyUs-card relative"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="py-6 px-6 flex flex-col w-full gap-4">
            <Image
              src={shieldUp}
              alt={"Customizable Security Solutions"}
              className="mb-6"
            />
            <h2 className="text-2xl font-bold">
              Customizable Security Solutions
            </h2>
            <p className="text-[#938DB5]">
              {`VulnVision is flexible enough to adapt to the security needs of any organization, from small businesses to large enterprises.`}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {[
                { text: "Fast Collect Data", icon: whyUsIcon1 },
                { text: "One-Click Deployment", icon: whyUsIcon2 },
                { text: "Automated Target Analysis", icon: whyUsIcon3 },
                { text: "AI-Enhanced Our Platform", icon: whyUsIcon4 },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="flex items-center"
                  data-aos="fade-right"
                  data-aos-delay={index * 100}
                >
                  <Image
                    src={feature.icon}
                    alt={feature.text}
                    className="mr-2"
                  />
                  <span className="text-sm">{feature.text}</span>
                </div>
              ))}
            </div>
          </div>
          <div
            className="w-full h-auto"
            style={{
              background: `url(${whyUs4.src})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            className="flex items-center justify-end flex-col gap-4 text-center whyUs-card py-8 h-[45rem] lg:h-full"
            style={{
              backgroundImage: `url(${whyUs5.src})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center center",
            }}
            data-aos="fade-right"
          >
            <h2 className="text-xl font-bold px-4">
              Continuous Updates and Support
            </h2>
            <p className="text-gray-400 px-4">
              Stay ahead of threats with continuous updates. Vulnvision delivers
              the latest security patches, features, and intelligence to keep
              your defenses strong. With 24/7 expert support, ensure your
              security posture remains resilient and up-to-date.
            </p>
          </div>
          <div
            className="flex items-center justify-between flex-col gap-4 text-center whyUs-card py-6"
            data-aos="fade-left"
          >
            <Image
              src={whyUs6}
              alt={"Collecting Threat Intelligence"}
              className="object-cover"
            />
            <h2 className="text-xl font-bold px-4">
              Collecting Threat Intelligence
            </h2>
            <p className="text-gray-400 px-4">
              Gain vital visibility into threats with real-time intelligence,
              helping you identify vulnerabilities and prioritize high-risk
              threats. Monitor cybercriminal activities to ensure your defenses
              are strong and responsive to emerging risks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const Feature = ({ icon, title, description, aosDelay }: any) => (
  <div
    className="w-full flex items-center justify-center flex-col gap-4 text-center whyUs-card py-6"
    data-aos="fade-up"
    data-aos-delay={aosDelay}
  >
    <Image
      src={icon}
      alt={title}
      className="w-full"
    />
    <h2 className="text-xl font-bold px-4">{title}</h2>
    <p className="text-gray-400 px-4">{description}</p>
  </div>
);

export default WhyUs;
