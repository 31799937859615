import React from "react";
import FeatureCard from "./FeatureCard";
import boltCircle from "@/public/images/icons/bolt-circle.svg";
import incognito from "@/public/images/icons/incognito.svg";
import shieldKeyhole from "@/public/images/icons/shield-keyhole.svg";
import magnifer from "@/public/images/icons/Magnifer-Icone.svg";
import magniferBug from "@/public/images/icons/magnifer-bug.svg";
import scanner from "@/public/images/icons/scanner.svg";
import eyeScan from "@/public/images/icons/eye-scan.svg";
import codeScan from "@/public/images/icons/code-scan.svg";
import GradientElement from "../GradientElement";
import MainTitle from "@/components/shared/MainTitle";

type Card = {
  icon: string;
  title: string;
  description: string;
  colSpan?: "full" | "2/3" | "1/3";
};

const cards: Card[] = [
  {
    icon: boltCircle,
    title: "Advanced Vulnerabilities Scan",
    description:
      "Vulnvision performs extensive scans across your entire IT infrastructure, including systems, applications, and networks. This ensures that every potential entry point is thoroughly examined for security weaknesses. ",
  },
  {
    icon: incognito,
    title: "Assets Discovery",
    description:
      "Experience comprehensive visibility with our Automated Asset Discovery. Effortlessly identify and catalog all your digital assets, including domains, subdomains, IP addresses, and web applications. With continuous updates, stay informed about new and altered assets, enabling your security team to manage and secure your attack surface efficiently.",
  },
  {
    icon: shieldKeyhole,
    title: "Darkweb Search",
    description:
      "Access and search the dark web for potential threats and leaked information. Vulnvision integrates with reputable intelligence sources to ensure secure and ethical data retrieval. Compliant with legal standards, it helps uncover and mitigate risks to your organization's cybersecurity. Instantly identify stolen credentials, infected devices, and third-party data exposures.",
  },
  {
    icon: magnifer,
    title: "Up-to-data Detection",
    description:
      " Detection: Utilizing the latest vulnerability databases, Vulnvision stays current with emerging threats, ensuring even newly discovered vulnerabilities are promptly identified.",
  },
  {
    icon: magniferBug,
    title: "Pentesting as a Service",
    description:
      "VulnVision keeps your defenses up-to-date with continuous updates, so you can stay one step ahead of emerging threats.",
  },
  {
    icon: scanner,
    title: "Scalability and Performance",
    description:
      "Adapt to the growing needs of your organization with a scalable infrastructure. Perform high-performance scans without compromising network and system performance, ensuring efficient and thorough security assessments.",
  },
  {
    icon: eyeScan,
    title: "Continuous Monitoring",
    description:
      "Maintain constant vigilance over your digital assets with continuous monitoring. Vulnvision offers real-time alerts and updates on new vulnerabilities, changes in asset status, and emerging threats. Monitor changes in your digital assets to ensure any modifications are promptly detected and assessed, keeping your security posture robust and up-to-date.",
  },
  {
    icon: codeScan,
    title: "Advanced Reporting",
    description:
      "Leverage advanced reporting features to generate insightful and comprehensive reports. Customize reports to meet the needs of different stakeholders, from technical teams to executive leadership, ensuring clear communication of security status and progress.",
  },
  {
    icon: codeScan,
    title: "Advanced analysis",
    description:
      "Effortlessly uncover hidden secrets, tokens, and credentials within your target environment. Vulnvision’s automated scanning capabilities not only identify sensitive information but also analyze the target to expose potential security flaws. This feature enables your offensive team to locate and leverage vulnerabilities quickly, ensuring no critical detail goes unnoticed.",
  },
];

const FeatureCardsGrid: React.FC = () => {
  return (
    <section
      id="features"
      className="flex flex-col items-center justify-center gap-4 overflow-x-clip"
    >
      <MainTitle subTitle="Our features" title="Explore Key Features" />
      <div className="holder w-full relative">
        <div className="absolute w-full h-full overflow-x-clip">
          <div
            className="absolute top-0 right-0 h-[108px] w-40"
            data-aos="fade-left"
          >
            <GradientElement />
          </div>
          <div
            className="absolute top-1/2 left-0 h-[108px] w-40"
            data-aos="fade-right"
          >
            <GradientElement />
          </div>
          <div
            className="absolute bottom-0 right-0 h-[108px] w-40"
            data-aos="fade-left"
          >
            <GradientElement />
          </div>
        </div>
        <div className="container py-10 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6">
          {cards.map((card, index) => (
            <FeatureCard
              key={index}
              index={index + 1}
              icon={card.icon}
              title={card.title}
              description={card.description}
              colSpan={card.colSpan}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureCardsGrid;
