import React from "react";
import MainTitle from "@/components/shared/MainTitle";
import Image from "next/image";
import mapImage from "@/public/images/assets/map.png";

type Props = {};

const AnalysesSection = (props: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <MainTitle
        subTitle="Impact in Numbers"
        title="Powering Your Security with Results"
        data-aos="fade-up"
      />
      <div className="container text-center pb-10 mt-5">
        <Image
          src={mapImage}
          alt="Vulnvistion"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1100"
        />
        <div className="flex gap-12 items-center justify-center flex-wrap">
          <div
            className="flex flex-col justify-start items-center gap-2"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="text-center text-[#8f69f8] text-5xl font-bold">
            60K+
            </div>
            <div className="text-center text-xl">
            Assets Secured
            </div>
          </div>
          <div
            className="flex flex-col justify-start items-center gap-2"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="text-center text-[#8f69f8] text-5xl font-bold">
              500+
            </div>
            <div className="text-center text-xl">Companies Trust Our Platform</div>
          </div>
          <div
            className="flex flex-col justify-start items-center gap-2"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="text-center text-[#8f69f8] text-5xl font-bold">
              10,000+
            </div>
            <div className="text-center text-xl">
              Dark Web Threats Mitigated
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysesSection;
