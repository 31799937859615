import React from "react";

type Props = {
  top?: string;
  left?: string;
};

const GradientElement: React.FC<Props> = ({ top, left }) => {
  return (
    <span
      className={`z-10 w-full h-[108px] -rotate-45 absolute top-${
        top ? top : "1/2"
      } left-${
        left ? left : "1/2"
      } -translate-x-1/2 -translate-y-1/2 bg-[#8F6AF8] blur-[160px] rounded-full`}
    ></span>
  );
};

export default GradientElement;
