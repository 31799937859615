import React, { useEffect } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import MainTitle from "@/components/shared/MainTitle";
import DemoButton from "../Buttons/DemoButton";
import Image from "next/image";
import mainLogo from "@/public/images/logos/mainLogo.svg";

type Props = {};

const FAQ = (props: Props) => {
  return (
    <section
      id="FAQ"
      className="flex flex-col gap-4 overflow-x-clip"
      data-aos="fade-up"
    >
      <MainTitle
        subTitle="F.A.Q"
        title="Frequently asked questions ?"
        data-aos="fade-up"
      />
      <div className="container text-center py-10">
        <FAQComponent />
      </div>
    </section>
  );
};

export default FAQ;

const faqData = [
  {
    question: "What is VulnVision?",
    answer:
      "VulnVision is a comprehensive cybersecurity tool designed to identify and address vulnerabilities in your digital infrastructure. It offers a range of features, including advanced vulnerability scanning, subdomain monitoring, darkweb search, port scanning, directory search, and more, to enhance your organization's cybersecurity posture.",
  },
  {
    question: "How does VulnVision's Advanced Vulnerabilities Scan work?",
    answer:
      "The Advanced Vulnerabilities Scan in VulnVision employs sophisticated algorithms and the latest vulnerability databases to conduct a thorough analysis of your systems, applications, and networks. It identifies potential security weaknesses, categorizes vulnerabilities by severity, and provides detailed reports with actionable recommendations for remediation.",
  },
  {
    question: "What is Subdomain Monitoring, and why is it important?",
    answer:
      "Subdomain Monitoring in VulnVision continuously tracks changes in the domain landscape, automatically detecting newly added subdomains. This feature enhances security awareness, allowing organizations to proactively address potential threats associated with their expanding digital footprint.",
  },
  {
    question: "Can VulnVision search the darkweb for potential threats?",
    answer:
      "Yes, VulnVision includes a Darkweb Search feature that integrates with reputable dark web intelligence sources. It enables users to search and retrieve information from the dark web securely and ethically, helping to identify potential risks and breaches that may impact their cybersecurity.",
  },
  {
    question:
      "How does the Port Scan feature contribute to security assessments?",
    answer:
      "The Port Scan feature in VulnVision performs a comprehensive scan of target systems, identifying open ports and associated services. This information is crucial for understanding the exposed attack surface and potential entry points for adversaries. The detailed results aid in crafting effective security strategies.",
  },
  {
    question:
      "What is Directory Search, and how does it enhance security assessments?",
    answer:
      "Directory Search in VulnVision enhances reconnaissance capabilities by allowing users to enumerate directories on web servers. This feature helps uncover hidden or sensitive paths, assisting in the identification of potential vectors for unauthorized access and data exposure.",
  },
];

const FAQComponent = () => {
  return (
    <div className="w-full mx-auto space-y-4">
      <Accordion
        type="single"
        collapsible
        className="w-full mb-8"
      >
        {faqData.map((faq, index) => (
          <AccordionItem
            key={index}
            value={`item-${index}`}
            className="border-none bg-[#1C17381A] my-3 py-4"
            data-aos="fade-up"
            data-aos-delay={index * 100}
          >
            <AccordionTrigger className="text-left text-xl font-medium text-white hover:no-underline px-4 py-3 rounded-lg">
              {faq.question}
            </AccordionTrigger>
            <AccordionContent className="text-left px-4 py-3 rounded-b-lg mt-1 text-[#938DB5]">
              {faq.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <VulnvisionCTA />
    </div>
  );
};

const VulnvisionCTA = () => {
  const sizes = [650, 530, 410, 290, 170];
  const colors = [
    "#9470F880",
    "#9470F880",
    "#9470F880",
    "#9470F880",
    "#9470F880",
  ];

  return (
    <div className="flex items-center container justify-center min-h-[680px] px-4 relative">
      <div className="absolute inset-0">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {sizes.map((size, index) => (
            <div
              key={size}
              className={`w-[${size}px] h-[${size}px] rounded-full border border-purple-200 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
              style={{
                opacity: 0.2 + index * 0.01,
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: colors[index % colors.length],
              }}
            ></div>
          ))}
        </div>
      </div>
      <div
        className="text-center z-10 max-w-xl w-full"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="flex items-center justify-center mb-6">
          <Image
            src={mainLogo}
            alt="Vulnvision"
            data-aos="zoom-in"
            data-aos-duration="800"
          />
          <span
            className="text-xl sm:text-2xl font-semibold"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="800"
          >
            Vulnvision
          </span>
        </div>
        <h2
          className="text-lg sm:text-xl md:text-2xl font-medium mb-6 sm:mb-8 mx-auto"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="800"
        >
          Sign up for a free trial or request a demo.
        </h2>
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="800"
        >
          <DemoButton />
        </div>
      </div>
    </div>
  );
};
